<template>
  <div>
    <div id="liqpay"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LiqPayWidget',

  data: () => ({
    scriptElement: null,
  }),

  computed: {
    ...mapState('companies', ['liqpayWidgetRegistrationData']),
  },

  created() {
    this.loadLiqPayScript()
  },

  mounted() {
    this.initializeLiqPay()
  },

  beforeDestroy() {
    this.removeLiqPayScript()
  },

  methods: {
    loadLiqPayScript() {
      const script = document.createElement('script')
      script.src = 'https://static.liqpay.ua/libjs/loader.liqpay.js'
      script.async = true
      // script.onload = this.initializeLiqPay
      document.head.appendChild(script)
      this.scriptElement = script
    },
    initializeLiqPay() {
      window.LiqPayCallback = (app) => {
        app.init({
          widget: 'pageregist',
          data: this.liqpayWidgetRegistrationData.data,
          signature: this.liqpayWidgetRegistrationData.signature,
          language: this.liqpayWidgetRegistrationData.language,
          embedTo: '#liqpay',
          mode: 'embed',
        })
      }
    },
    removeLiqPayScript() {
      if (this.scriptElement) {
        document.head.removeChild(this.scriptElement)
      }
    },
  },
}
</script>
