<template>
  <v-container fluid>
    <template v-if="!editCard">
      <div class="mb-5">
        <span class="mr-1">Публічний ключ LiqPay:</span>
        <span class="font-weight-medium">{{ currentItem.liqpay_id }}</span>
      </div>

      <div class="mb-5">
        <span class="mr-1">Комісія системи:</span>
        <span class="font-weight-medium">{{ currentItem.payment_commission }}%</span>
      </div>

      <div>
        <p>Якщо Компанія не зареєстрована, то Ви маєте можливість зареєструвати Компанию у системі Liqpay</p>
      </div>
      <div class="widget">
        <v-btn @click="showLiqpayBlock = !showLiqpayBlock" small>Зареєструватися у Liqpay?</v-btn>
        <div v-show="showLiqpayBlock">
          <LiqPayWidget v-if="isLoaded" />
        </div>
      </div>
    </template>

    <template v-else>
      <div class="custom-field mb-5 d-flex align-baseline">
        <span class="mr-1">Публічний ключ LiqPay:</span>
        <v-text-field v-model.trim="currentCompany.liqpay_id" dense hide-details></v-text-field>
      </div>

      <div class="mb-5 d-flex align-baseline">
        <span class="mr-1">Комісія системи:</span>
        <v-text-field
          v-model.number="currentCompany.payment_commission"
          suffix="%"
          dense
          hide-details
          style="max-width: 50px"
        ></v-text-field>
      </div>
    </template>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import LiqPayWidget from '@/components/partials/LiqPayWidget'

export default {
  name: 'PaymentSystem',

  components: {
    LiqPayWidget,
  },

  props: {
    currentItem: {
      type: Object,
      required: true,
      default: () => {},
    },
    isDataSaved: {
      type: Boolean,
      required: true,
      default: false,
    },
    currentTabName: {
      required: true,
      default: null,
    },
    editCard: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      currentCompany: { ...this.currentItem },
      showLiqpayBlock: false,
      isLoaded: false,
    }
  },

  created() {
    this.initialize()
  },

  watch: {
    isDataSaved(val) {
      if (val && this.currentTabName === 'paymentSystem') {
        this.$emit('saveData', this.currentCompany)
      }
    },
    editCard(val) {
      if (!val) this.showLiqpayBlock = false
    },
  },

  methods: {
    ...mapActions('companies', ['loadLiqpayWidgetRegistrationData']),

    async initialize() {
      await this.loadLiqpayWidgetRegistrationData(this.currentCompany.id)
      this.isLoaded = true
    },
  },
}
</script>

<style scoped lang="scss">
.custom-field {
  max-width: 600px;
}
</style>
